/* import __COLOCATED_TEMPLATE__ from './advanced-settings-websync.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type { TrackedArray } from 'tracked-built-ins';
import type IntlService from 'embercom/services/intl';

export type addModalUpdateParams = {
  additionalUrls: string;
  removeCssSelectors: string;
  clickCssSelector: string;
  waitForSelector: string;
  useSitemap: boolean;
  relativeDateMonths?: number;
  dateSelector?: string;
  includePagesWithNoDate?: boolean;
};

interface Args {
  form: any;
  sourceUrl?: string;
  updateExcludedUrl: (index: number, event: InputEvent) => void;
  deleteExcludedUrl: (index: number) => void;
  addExcludedUrl: () => void;
  toggleSitemap: () => void;
  updateValues: (params: addModalUpdateParams) => void;
  trackAdditionalUrlsAnalytics: () => void;
  isRemoveCssSelectorInvalid: boolean;
  isClickCssSelectorInvalid: boolean;
  isWaitForSelectorInvalid: boolean;
  isDateSelectorInvalid: boolean;
  isRelativeDateInvalid: boolean;
  isNewSource: boolean;
  isAddingNewSource: boolean;
  isFormInvalid?: boolean;
  isAdditionalUrlsInvalid?: boolean;
  additionalUrls: string;
  excludedUrls: TrackedArray<string>;
  removeCssSelectors: string;
  clickCssSelector: string;
  useSitemap: boolean;
  waitForSelector: string;
  relativeDateMonths?: number;
  dateSelector?: string;
  includePagesWithNoDate?: boolean;
}

export default class AdvancedSettingsWebsync extends Component<Args> {
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare appService: any;

  @tracked additionalUrls = this.args.additionalUrls;
  @tracked useSitemap = this.args.useSitemap;
  @tracked includePagesWithNoDate = this.args.includePagesWithNoDate;

  sourceUrl = this.args.sourceUrl || '';
  removeCssSelectors = this.args.removeCssSelectors;
  clickCssSelector = this.args.clickCssSelector;
  waitForSelector = this.args.waitForSelector;
  relativeDateMonths = this.args.relativeDateMonths;
  dateSelector = this.args.dateSelector;

  @action
  deleteExcludedUrl(index: number) {
    this.args.deleteExcludedUrl(index);
  }

  @action
  addExcludedUrl() {
    this.args.addExcludedUrl();
  }

  @action
  toggleSitemap() {
    this.useSitemap = !this.useSitemap;
    this.trackAnalyticsEvent('clicked', 'use_sitemap_switch');
    this.onFieldUpdate();
  }

  @action
  toggleIncludePagesWithNoDate() {
    this.includePagesWithNoDate = !this.includePagesWithNoDate;
    this.onFieldUpdate();
    this.trackAnalyticsEvent('clicked', 'include_pages_with_no_date_switch');
  }

  @action
  onFieldUpdate() {
    let params = {
      additionalUrls: this.additionalUrls,
      removeCssSelectors: this.removeCssSelectors,
      clickCssSelector: this.clickCssSelector,
      waitForSelector: this.waitForSelector,
      useSitemap: this.useSitemap,
      relativeDateMonths: this.relativeDateMonths,
      dateSelector: this.dateSelector,
      includePagesWithNoDate: this.includePagesWithNoDate,
    } as addModalUpdateParams;
    this.args.updateValues(params);
  }

  @action trackAdditionalUrlsAnalytics() {
    this.args.trackAdditionalUrlsAnalytics();
  }

  @action trackRemoveCssSelectorAnalytics() {
    this.trackAnalyticsEvent('inputed', 'remove_css_selector_input');
  }

  @action trackClickCssSelectorAnalytics() {
    this.trackAnalyticsEvent('inputed', 'click_css_selector_input');
  }

  @action trackWaitForSelectorAnalytics() {
    this.trackAnalyticsEvent('inputed', 'wait_for_selector_input');
  }

  @action updateExcludedUrl(index: number, event: InputEvent) {
    this.args.updateExcludedUrl(index, event);
  }

  get isRemoveCssSelectorInvalid(): boolean {
    return this.args.isRemoveCssSelectorInvalid;
  }

  get isClickCssSelectorInvalid(): boolean {
    return this.args.isClickCssSelectorInvalid;
  }

  get isWaitForSelectorInvalid(): boolean {
    return this.args.isWaitForSelectorInvalid;
  }

  get additionalUrlsErrorMessage(): string {
    return this.args.isNewSource
      ? this.intl.t(
          'ai-content-library.sources.add-source-modal.additional-urls-new.validation-error',
        )
      : this.intl.t(
          'ai-content-library.sources.add-source-modal.additional-urls-new.validation-error-edit',
          { url: this.sourceUrl },
        );
  }

  get showDateOptions(): boolean {
    return this.args.relativeDateMonths !== undefined || this.args.isNewSource;
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      context: 'side_drawer',
      place: 'external_content',
      section: 'operator',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Sources::AdvancedSettingsWebsync': typeof AdvancedSettingsWebsync;
  }
}
